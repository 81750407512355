.root {
  width: 85%;
  position: relative;
  margin-bottom: 20px;
}
.latest {
  padding: 5px 10px;
  border-radius: 0 10px 0 10px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: #67ab23d2;
}
.bubble {
  border-radius: 10px;
  padding: 5px 0px 10px 0px;
  overflow: hidden;
  cursor: default;
  white-space: pre-wrap;
}
.inner {
  padding: 0px 5px 0px 10px;
}
.my_message_root {
  margin: 0px 0px 12px auto;
  border-radius: 10px;
  background-color: #e5f1fc;
  .bubble {
    background-color: #e5f1fc;
    ol {
      line-height: 1.0;
    }
  }
  line-height: 1.2;
}
.author {
  padding: 5px 10px;
  font-size: 10px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px 0 10px 0;
}
.date {
  font-size: 8px;
}
.attachments {
  font-size: 10px;
  font-weight: bold;
  margin: 0 10px 0 10px;
  display: none;
  transition: all 300ms ease-in;
}
.response_root {
  margin: 0px auto 12px 0px;
  border-radius: 10px;
  background-color: #f0f2f5;
  .bubble {
    background-color: #f0f2f5;
    ol {
      line-height: 1.0;
    }
  }
  line-height: 1.2;
}
