@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;1,6..12,300&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  --antd-wave-shadow-color: #82BA3F !important;
}

a {
  color: inherit;
  text-decoration: none;
  color: #1677ff
}

* {
  box-sizing: border-box;
}

h3 {
  font-weight: 100;
}

.flex {
  display: flex;
}
.flex-right {
  display: flex;
  justify-content: right;
  width: 100%;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.flex-right {
  display: flex;
  justify-content: right;
}
hr.solid {
  border: 1px solid #bbb;
  border-radius: 1px;
  opacity: 0.25;
}
.small-margin {
  margin-bottom: 5px !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
.hover {
  .hover:hover {
    background-color: blue;
  }
}

.ant-table {
  overflow-x: auto !important;
  .ant-table-container {
    min-width: 650px !important;
  }
}
.ql-editor {
  min-height: 200px;
}
// Main Font Familty - Nunito Sans - Google Font - https://fonts.google.com/specimen/Nunito+Sans
.nunito-sans {
  font-family: 'Nunito Sans', sans-serif;
}
.ec-color {
  color: #67AB23 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #82BA3F !important;
}

// Button color styles
.ant-btn-primary {
  color: #fff;
  border-color: #82BA3F !important;
  background: #82BA3F !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

// Checkbox color styles
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #99b82d !important;
  border-color: #99b82d;
}
.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #99b82d !important;
  border-color: #99b82d !important;
}
.ant-checkbox-checked:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #99b82d !important;
  border-color: #99b82d !important;
}

.ant-checkbox-wrapper:focus .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #99b82d !important;
  border-color: #99b82d !important;
}

.ant-checkbox-checked:after {
  border-color: #99b82d !important;
}
.ant-checkbox:hover::after {
  border-color: #99b82d !important;
}
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #99b82d !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: grey !important;
  background-color: #67AB23 !important;
}

.ant-switch-checked {
  background-color: #82BA3F !important;
}

#case-messages-area p {
  margin: 0 !important;
}
#case-messages-area img {
  width: auto;
  height: 200px;
  border-radius: 5px;
}
#case-messages-area img:hover {
  cursor: zoom-in;
}
.case-message-attachment {
  font-size: 10px;
  font-weight: normal;
  padding-left: 5px;
}

.contact-permissions-container-top {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  width: 100%;
  background-color: #fcfcfc;
  padding: 20px 20px 0 20px;
  border-radius: 3px 3px 0 0;
  border-top: solid 1px #ecebeb;
  border-right: solid 1px #ecebeb;
  border-left: solid 1px #ecebeb;
}

.contact-permissions-container-bottom {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  width: 100%;
  background-color: #fcfcfc;
  padding: 0px 20px;
  border-radius: 0 0 3px 3px;
  border-bottom: solid 1px #ecebeb;
  border-right: solid 1px #ecebeb;
  border-left: solid 1px #ecebeb;
}

.contact-permissions-container-bottom-admin {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  width: 100%;
  background-color: #fcfcfc;
  padding: 20px 20px 0px 20px;
  border-radius: 3px;
  border: solid 1px #ecebeb;
}

.inactive {
  background-color: #f5f5f5;
}
.inactive-switch-container {
  display: flex;
  padding: 7px 12px 7px 12px;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.043) 0px 2px 0px 0px
}