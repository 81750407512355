$color:rgb(109, 151, 63);

.pdfname{
    display: inline-block;
    padding-top: 7px;
    font-size: 14px;
}
.watermarkBlock{
    display: inline;
    // margin-left: 35px;
    float: right;
}
.watermarkBtn{
    border-radius: 5px;
    margin-top: 14px;
    height: 38px;
    border: solid 1px rgb(109, 151, 63);
    padding: 8px;
    background-color: rgb(109, 151, 63);
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}

.watermarkBtn {
    display: inline-block;
    padding: 0.5rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color;
      border-radius: 10rem;
      z-index: -2;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken($color, 15%);
      transition: all .3s;
      border-radius: 10rem;
      z-index: -1;
    }
    &:hover {
      color: #fff !important;
      &:before {
        width: 100%;
      }
    }
  }

@media only screen and (max-width: 768px) {
    .watermarkBlock{
        display: none;
        width: 100%;
        margin-top: 10px;
    }

    .watermarkBtn{
        border-radius: 2px;
        border: solid 1px rgb(109, 151, 63);;
        padding: 8px;
        background-color: rgb(109, 151, 63);;
        color: white;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
    }
}
